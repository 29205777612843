import React from "react";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import MenuBar from "../components/MenuBar";
import Spinner from "../components/Spinner";

import { useAuthContext } from "../contexts/authContext";

const ErrorPage: React.FC = () => {
  const { logout } = useAuthContext();

  React.useEffect(() => {
    logout();
    navigate("/login");
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logging out...</title>
      </Helmet>
      <main className="w-screen h-screen bg-off-white flex flex-center">
        <MenuBar NorthstarMenu={true} />
        <Spinner active={true} />
      </main>
    </>
  );
};

export default ErrorPage;
